<template>
  <base-layout :title="title" :backLink="backLink" :reloadable="reloadable">
    <ion-grid fixed>
      <ion-row>
        <ion-col size="12">
          <ion-list>
            <ion-item class="ion-margin-bottom" lines="none">
              <ion-label position="floating">Notification Date</ion-label>
              <ion-datetime
                type="text"
                v-model="date"
                placeholder="Select Date"
                disabled
              ></ion-datetime>
              <ion-button fill="solid" slot="end" @click="confirmNotification">
                Send Notification
              </ion-button>
            </ion-item>
            <ion-item>
              <ion-label position="floating">Notification Type</ion-label>
              <ion-select
                v-if="notificationTypes.length > 0"
                v-model="notification_type_id"
                :value="notificationTypes[0].id"
              >
                <ion-select-option
                  v-for="notification_type of notificationTypes"
                  :key="notification_type.id"
                  :value="notification_type.id"
                >
                  {{ `${notification_type.name}` }}
                </ion-select-option>
              </ion-select>
            </ion-item>

            <ion-item :class="{ 'color-danger': v$.name.$error }">
              <ion-label position="floating">Title</ion-label>
              <ion-input type="text" v-model="name"></ion-input>
            </ion-item>
            <ion-item :class="{ 'color-danger': v$.description.$error }">
              <ion-label position="floating">Body</ion-label>
              <ion-textarea
                type="text"
                v-model="description"
                rows="4"
              ></ion-textarea>
            </ion-item>
          </ion-list>
        </ion-col>
        <ion-col size="12" v-if="classrooms && teachers.length > 0">
          <ion-item lines="none">
            <h5>
              Select Recipients (Selected: {{ selected_recipients.length }})
            </h5>
          </ion-item>
          <ion-item lines="none">
            <ion-label>Select everyone</ion-label>
            <ion-checkbox
              checked
              slot="end"
              style="margin-bottom: 0; margin-top: 0"
              @ionChange="toggleAll($event)"
            ></ion-checkbox>
          </ion-item>

          <ion-card>
            <ion-card-header style="padding-bottom: 0">
              <ion-card-title> Teachers </ion-card-title>
              <ion-item lines="none">
                <ion-label>Select all Teachers</ion-label>
                <ion-checkbox
                  class="select-all-in-class"
                  checked
                  slot="end"
                  style="margin-bottom: 0; margin-top: 0"
                  @ionChange="toggleAllTeacher($event)"
                ></ion-checkbox>
              </ion-item>
            </ion-card-header>
            <ion-card-content>
              <ion-item>
                <ion-label position="floating">Teachers</ion-label>
                <ion-select
                  multiple
                  :interface-options="{ cssClass: 'full-screen' }"
                  v-model="user_ids['teachers']"
                  :value="getValues(teachers)"
                >
                  <ion-select-option
                    v-for="teacher of teachers"
                    :key="teacher.id"
                    :value="teacher.user_id"
                  >
                    {{ `${teacher.user.user_detail.name}` }}
                  </ion-select-option>
                </ion-select>
              </ion-item>
            </ion-card-content>
          </ion-card>

          <ion-card v-for="item of classrooms" :key="item.id">
            <ion-card-header style="padding-bottom: 0">
              <ion-card-title
                >Class {{ item.standard.name }} - {{ item.section.name }}
                <span v-if="user_ids[item.id]"
                  >({{
                    user_ids[item.id].length + "/" + item.students.length
                  }})</span
                >
              </ion-card-title>
              <ion-item lines="none">
                <ion-label>Select entire class</ion-label>
                <ion-checkbox
                  class="select-all-in-class"
                  checked
                  :value="item.id"
                  slot="end"
                  style="margin-bottom: 0; margin-top: 0"
                  @ionChange="toggleSelectAll(item, $event)"
                ></ion-checkbox>
              </ion-item>
            </ion-card-header>
            <ion-card-content>
              <ion-item>
                <ion-label position="floating">Students</ion-label>
                <ion-select
                  multiple
                  :interface-options="{ cssClass: 'full-screen' }"
                  v-model="user_ids[item.id]"
                  :value="getValues(item.students)"
                >
                  <ion-select-option
                    v-for="student of item.students"
                    :key="student.id"
                    :value="student.user_id"
                  >
                    {{ `${student.user.user_detail.name}` }}
                  </ion-select-option>
                </ion-select>
              </ion-item>
            </ion-card-content>
          </ion-card>
        </ion-col>
      </ion-row>
    </ion-grid>
  </base-layout>
</template>

<script>
import BaseLayout from "../../../Base/BaseLayout.vue";
import {
  IonGrid,
  IonRow,
  IonCol,
  IonList,
  IonItem,
  IonLabel,
  IonDatetime,
  IonInput,
  IonTextarea,
  IonButton,
  IonCard,
  IonCardHeader,
  IonCardTitle,
  IonCheckbox,
  IonCardContent,
  IonSelect,
  IonSelectOption,
  loadingController,
  alertController,
  toastController,
} from "@ionic/vue";

import { required, maxLength, helpers } from "@vuelidate/validators";
import { useVuelidate } from "@vuelidate/core";

export default {
  components: {
    BaseLayout,
    IonGrid,
    IonRow,
    IonCol,
    IonList,
    IonItem,
    IonLabel,
    IonDatetime,
    IonInput,
    IonTextarea,
    IonButton,
    IonCard,
    IonCardHeader,
    IonCardTitle,
    IonCheckbox,
    IonCardContent,
    IonSelect,
    IonSelectOption,
  },
  data() {
    return {
      title: "Send App Notification",
      backLink: "communication",
      reloadable: false,

      date: new Date().toISOString(),

      user_ids: {},
      name: null,
      description: null,
      notification_type_id: null,

      xhrError: {
        header: null,
        description: null,
      },
    };
  },

  computed: {
    classrooms() {
      return this.$store.getters["notification/allResources"];
    },

    teachers() {
      return this.$store.getters["teacher/allResources"];
    },

    notificationTypes() {
      return this.$store.getters["attribute/notificationTypes"];
    },

    selected_recipients() {
      let user_ids = [];
      for (let key in this.user_ids) {
        this.user_ids[key].forEach((element) => {
          user_ids.push(element);
        });
      }
      return user_ids;
    },
  },

  setup() {
    return { v$: useVuelidate() };
  },

  validations() {
    return {
      name: {
        required: helpers.withMessage("is required", required),
        maxLength: helpers.withMessage(
          "maximum 255 characters",
          maxLength(255)
        ),
      },
      description: {
        required: helpers.withMessage("is required", required),
        maxLength: helpers.withMessage(
          "maximum 255 characters",
          maxLength(255)
        ),
      },
    };
  },

  methods: {
    getValues(students) {
      let values = [];

      for (let key in students) {
        values.push(students[key].user_id);
      }
      return values;
    },

    getTeacherValues(teachers) {
      let values = [];
      for (let key in teachers) {
        values.push(teachers[key].user.id);
      }
      return values;
    },

    async toggleSelectAll(item, event) {
      let values = [];

      if (event.detail.checked) {
        for (let key in item.students) {
          values.push(item.students[key].user_id);
        }
      }

      this.user_ids[item.id] = values;
    },

    async toggleAllTeacher(event) {
      let values = [];
      if (event.detail.checked) {
        let teachers = this.teachers;
        for (let key in teachers) {
          values.push(teachers[key].user.id);
        }
      }
      this.user_ids["teachers"] = values;
    },

    async toggleAll(event) {
      const toggles = document.querySelectorAll(".select-all-in-class");
      if (event.detail.checked) {
        for (let i = 0; i < toggles.length; i++) {
          toggles[i].checked = true;
        }
      } else {
        for (let i = 0; i < toggles.length; i++) {
          toggles[i].checked = false;
        }
      }
    },

    loader(message) {
      const loading = loadingController.create({
        message: message,
        backdropDismiss: false,
      });

      return loading;
    },

    alerter(header, message, buttons = ["close"]) {
      const alert = alertController.create({
        header: header,
        message: message,
        buttons: buttons,
      });

      return alert;
    },

    toaster(message) {
      const toast = toastController.create({
        message,
        duration: 3000,
        color: "primary",
      });

      return toast;
    },

    async confirmNotification() {
      const header = `Confirm`;
      const message = `Are you sure you want to send Notification?`;
      const buttons = [
        {
          text: "Cancel",
          role: "cancel",
          cssClass: "secondary",
        },
        {
          text: "Confirm",
          handler: () => {
            this.sendNotification();
          },
        },
      ];
      const alertDialog = await this.alerter(header, message, buttons);

      alertDialog.present();
    },

    async sendNotification() {
      let data = {
        user_ids: this.selected_recipients,
        name: this.name,
        description: this.description,
        notification_type_id: this.notification_type_id,
      };
      const spinner = await this.loader("Sending...");
      spinner.present();

      try {
        await this.$store.dispatch("notification/sendNotification", data);
        spinner.dismiss();
        const toast = await this.toaster("App notification created");
        await toast.present();
        this.$router.replace({ name: "communication" });
      } catch (error) {
        spinner.dismiss();
        this.setErrorResponse(error);
        const alertDialog = await this.alerter(
          this.xhrError.header,
          this.xhrError.message
        );
        alertDialog.present();
      }
    },

    async selectAllStudents() {
      this.classrooms.forEach((classroom) => {
        this.user_ids[classroom.id] = [];
        classroom.students.forEach((student) => {
          this.user_ids[classroom.id].push(student.user_id);
        });
      });
    },

    async selectAllTeachers() {
      let value = [];
      let teachers = this.teachers;
      for (let key in teachers) {
        value.push(teachers[key].user.id);
      }
      this.user_ids["teachers"] = value;
    },

    async fetchStudents() {
      await this.$store.dispatch("notification/allResources");
      await this.selectAllStudents();
    },

    async fetchTeachers() {
      await this.$store.dispatch("teacher/allResources");
      await this.selectAllTeachers();
    },

    async fetchNotificationTypes() {
      await this.$store.dispatch("attribute/notificationTypes");
    },
  },

  created() {
    this.fetchStudents();
    this.fetchTeachers();
    this.fetchNotificationTypes();
  },
};
</script>

<style scoped></style>
